import cn from 'classnames';
import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import { ButtonSize, ButtonVariant } from '@monorepo/types';

import { ButtonIcon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseButton } from '../BaseButton/BaseButton';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
	label: string;
	variant?: ButtonVariant;
	size?: ButtonSize;
	isLoading?: boolean;
	iconLeft?: string;
	iconRight?: ReactNode;
};

export const buttonClassNames: Record<ButtonVariant, string> = {
	cta: `text-grayscale0 bg-success300 border-transparent
	hover:enabled:bg-success200
	active:enabled:bg-success400
	disabled:bg-grayscale300`,
	ctaInfo: `text-grayscale0 bg-info300 border-transparent
	hover:enabled:bg-info200
	active:enabled:bg-info400
	disabled:bg-grayscale300`,
	primary: `text-grayscale0 bg-primaryMain border-transparent
	hover:enabled:bg-primary400
	active:enabled:bg-primary600
	disabled:bg-grayscale300`,
	secondary: `text-primaryMain bg-grayscale0 border-primaryMain
	hover:enabled:bg-primary50 hover:enabled:text-primary400 hover:enabled:border-primary400
	active:enabled:bg-primary100 active:enabled:text-primary600 active:enabled:border-primary600
	disabled:bg-grayscale0 disabled:text-grayscale300 disabled:border-grayscale300`,
	tertiary: `bg-primary50 text-primaryMain border-transparent
	hover:enabled:bg-primary100
	hover:enabled:text-primaryMain active:enabled:bg-primary200
	disabled:text-grayscale300 disabled:bg-grayscale50`,
	ctaSecondary: `text-grayscale0 bg-secondaryMain border-transparent
	hover:enabled:bg-secondary300
	active:enabled:bg-secondary600
	disabled:bg-grayscale300`,
	warning: `text-grayscale0 bg-warning300 border-transparent
	hover:enabled:bg-warning200
	active:enabled:bg-warning400
	disabled:bg-grayscale300`,
	error: `text-grayscale0 bg-error300 border-transparent
	hover:enabled:bg-error200
	active:enabled:bg-error400
	disabled:bg-grayscale300`,
};

export const buttonSizes: Record<ButtonSize, string> = {
	sm: 'h-40 md:h-40 space-x-4 p-8',
	md: 'h-48 md:h-48 space-x-4 p-12',
	lg: 'h-48 md:h-56 space-x-8 p-12 md:px-20 md:py-16',
};

export const StandardButton: FC<Props> = ({
	label,
	className,
	disabled,
	variant = 'primary',
	size = 'md',
	isLoading,
	iconLeft,
	iconRight,
	...props
}) => {
	const buttonIconMode = variant === 'secondary' || variant === 'tertiary' ? 'dark' : 'light';

	return (
		<BaseButton
			className={cn(
				className,
				buttonClassNames[variant],
				!className?.includes('h-') && !className?.includes('p-') && buttonSizes[size],
				'rounded-6 whitespace-nowrap border-2'
			)}
			disabled={disabled || isLoading}
			{...props}>
			{iconLeft && (
				<ButtonIcon
					icon={iconLeft}
					size={size === 'lg' ? 'lg' : 'md'}
					iconPosition='left'
					isLoading={isLoading}
					mode={buttonIconMode}
				/>
			)}

			<Text
				variant={size === 'lg' ? 'body-l' : size === 'md' ? 'body-m' : 'body-s'}
				color='inherit'
				weight='semibold'
				className='truncate'>
				{label}
			</Text>

			{!iconLeft && isLoading ? (
				<ButtonIcon
					icon=''
					size={size === 'lg' ? 'lg' : 'md'}
					iconPosition='right'
					isLoading={isLoading}
					mode={buttonIconMode}
				/>
			) : typeof iconRight === 'string' ? (
				<ButtonIcon
					icon={iconRight}
					size={size === 'lg' ? 'lg' : 'md'}
					iconPosition='right'
					isLoading={isLoading}
					mode={buttonIconMode}
				/>
			) : typeof iconRight === 'object' ? (
				iconRight
			) : null}
		</BaseButton>
	);
};
