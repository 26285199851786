import { useMemo } from 'react';

import { CartProduct, ProductDocument } from '@monorepo/types';

import { SummaryListProps } from '@common/components/summary-list/summary-list';

type UseSummaryListItems = {
	products: Array<CartProduct>;
	documents: Array<ProductDocument>;
};
export function useSummaryListItems({ documents, products }: UseSummaryListItems) {
	// TODO: This should be cleaned up, right now the client acceptance page is only made for the broker studio camper single product
	const summaryItems = useMemo(() => {
		const items: SummaryListProps['items'] = [];

		for (const product of products) {
			if (product.object?.reference) {
				const type = product.object.specs.find(({ reference }) => reference === 'vehicle_type')?.answer.readable;
				const brand = product.object.specs.find(({ reference }) => reference === 'brand')?.answer.readable;
				const firstAdmissionDate = product.object.specs.find(({ reference }) => reference === 'date_first_admission')
					?.answer.readable;

				items.push({
					type: 'vehicle',
					args: {
						brand: brand ?? '',
						licenseplate: product.object.reference,
						model: type ?? '',
						year: firstAdmissionDate ?? '',
					},
				});
			}

			const productDocuments = documents.find(({ composition }) => composition?.guid === product.composition);

			items.push({
				type: 'product',
				args: {
					name: product.name,
					icon: product.icon,
					price: product.price?.premium_after_tax ?? 0,
					specs: product.specs,
					documents: productDocuments,
					addons: product.add_ons
						.filter((addon) => addon.answer.value === 'J')
						.map(({ icon, phrasing, price }) => ({ icon, phrasing, price: price?.premium_after_tax ?? 0 })),
				},
			});
		}

		return items;
	}, [documents, products]);

	return summaryItems;
}
