import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { type FC } from 'react';

import { StaticNotification } from '@monorepo/components/DataDisplay';
import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { IconItem, IconList, NumberedList, Text } from '@monorepo/components/TextComponents';
import { SummarySchema } from '@monorepo/shared/lib/schemas';
import { scrollToPosition } from '@monorepo/shared/lib/utils';
import { FlowState, InsuranceDocs, MediaTypes } from '@monorepo/types';

import { paths } from '@common/utils';

type SummaryFormProps = {
	state: FlowState;
	documents: InsuranceDocs;
	isB2B: boolean;
	isPortal: boolean;
	isIbanPrefilled?: boolean;
	isInReview?: boolean; // indicates whether we're in approval after review or not
	isLoading?: boolean;
	onSubmit: (fields: SummarySchema) => void;
};

export const SummaryForm: FC<SummaryFormProps> = ({
	state,
	documents,
	isInReview,
	isLoading,
	isPortal,
	isIbanPrefilled = false,
	isB2B,
	onSubmit,
}) => {
	const { t } = useTranslation(['common']);

	const termsConditionsDocument = documents?.documents?.find((document) => document.type === MediaTypes.GeneralTerms);
	const serviceGuidDocument = documents?.documents?.find((document) => document.type === MediaTypes.ServiceGuide);

	return (
		<Form.Context<SummarySchema>>
			{({ handleSubmit, formState: { errors, isValid }, register, control, watch }) => {
				return (
					<>
						{(state === 'wel-itc-happy' || state === 'wel-itc-sad') && (
							<StaticNotification status='info'>
								<Text weight='semibold' variant='body-m'>
									{t('page.funnel.finalize.summary.inReview.requestExpectations.title')}
								</Text>

								<NumberedList
									className='mt-16'
									items={[
										...(state === 'wel-itc-sad'
											? [
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.1'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.2'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.3'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.4'),
												]
											: []),
										...(state === 'wel-itc-happy'
											? [
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.5'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.6'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.7'),
												]
											: []),
									]}
								/>
							</StaticNotification>
						)}

						<div className='rounded-18 bg-grayscale0 shadow-1 p-24'>
							<Form onSubmit={handleSubmit(onSubmit)} className='space-y-32'>
								<div className='space-y-40'>
									{(state === 'wel-itc-happy' ||
										state === 'geen-itc-happy' ||
										state === 'inreview-wel-itc-sad' ||
										state === 'inreview-geen-itc-sad') && (
										<div className='space-y-24'>
											<Form.Group isRendered={state === 'geen-itc-happy' || state === 'inreview-geen-itc-sad'}>
												<Form.Label>{t('forms.summaryForm.fields.effectiveDateLabel')}</Form.Label>
												<Form.DateInput
													fieldSize='lg'
													// min={cart?.minimum_effective_date}
													name='effective_date'
													control={control}
													errors={errors.effective_date}
													placeholder={t('forms.summaryForm.fields.effectiveDatePlaceholder')}
													endIcon='calendar-alt'
													register={register}
													className='w-full'
													data-testid='summary-form-date-input'
												/>
											</Form.Group>

											<Form.Group
												isRendered={
													state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad'
												}>
												<Form.Label>{t('forms.summaryForm.fields.ibanTitle')}</Form.Label>
												<Form.IbanInput
													fieldSize='lg'
													name='iban'
													disabled={isIbanPrefilled}
													startIcon='credit-card'
													placeholder='NL60ABNA9238197806'
													errors={errors.iban}
													register={register}
													className='w-full'
													data-testid='summary-form-iban-input'
												/>
											</Form.Group>
										</div>
									)}
									<div className='space-y-32'>
										<div className='space-y-24'>
											<Text variant='body-m' weight='semibold'>
												{t(
													isInReview ? 'forms.summaryForm.checklist.titleInReview' : 'forms.summaryForm.checklist.title'
												)}
											</Text>
											<IconList>
												{isB2B && (
													<IconItem
														item={{
															icon: 'truck-loading',
															titleWeight: 'regular',
															title: t('forms.summaryForm.checklist.items.6'),
														}}
													/>
												)}

												{(state === 'wel-itc-happy' || state === 'inreview-wel-itc-sad') && (
													<IconItem
														item={{
															icon: 'times-circle',
															titleWeight: 'regular',
															title: t('forms.summaryForm.checklist.items.1'),
														}}
													/>
												)}

												{(state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad') && (
													<IconItem
														item={{
															icon: 'sync',
															titleWeight: 'regular',
															title: t('forms.summaryForm.checklist.items.2'),
														}}
													/>
												)}

												{(state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad') &&
													!isPortal && (
														<IconItem
															item={{
																icon: 'university',
																titleWeight: 'regular',
																title: t('forms.summaryForm.checklist.items.3'),
															}}
														/>
													)}

												{(state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad') && (
													<IconItem
														item={{
															icon: 'file-alt',
															titleWeight: 'regular',
															title: (
																<Trans
																	i18nKey={'forms.summaryForm.checklist.items.4'}
																	components={{
																		scroll: (
																			<span
																				className='text-info300 cursor-pointer underline'
																				onClick={() => scrollToPosition('insurance-documents')}
																			/>
																		),
																	}}
																/>
															),
														}}
													/>
												)}

												<IconItem
													item={{
														icon: 'file-bookmark-alt',
														titleWeight: 'regular',
														title: (
															<Trans
																i18nKey={'forms.summaryForm.checklist.items.5'}
																components={{
																	terms: (
																		<Link
																			target='_blank'
																			title={`${termsConditionsDocument?.name}`}
																			href={`/${termsConditionsDocument?.path}`}
																			className='text-info300 underline'
																		/>
																	),
																	serviceGuide: (
																		<Link
																			target='_blank'
																			title={`${serviceGuidDocument?.name}`}
																			href={`/${serviceGuidDocument?.path}`}
																			className='text-info300 underline'
																		/>
																	),
																	privacy: (
																		<Link
																			target='_blank'
																			rel='noreferrer'
																			href={paths.content.privacy_statement}
																			className='text-info300 underline'
																		/>
																	),
																}}
															/>
														),
													}}
												/>
											</IconList>
										</div>

										<Form.AcceptanceStatementCheckbox
											name='terms_agreement'
											label={t('forms.summaryForm.fields.accept')}
											register={register}
											checked={watch('terms_agreement')}
											data-testid='summary-form-agreement-input'
										/>
									</div>
								</div>

								{/* Submit */}
								<StandardButton
									type='submit'
									variant='cta'
									label={t(
										isInReview || state === 'wel-itc-happy' || state === 'geen-itc-happy'
											? 'common.ctaCloseOff'
											: 'common.ctaRequest'
									)}
									isLoading={isLoading}
									disabled={!isValid || isLoading}
									className='w-full'
									iconRight='angle-right'
									data-testid='summary-form-submit-button'
								/>
							</Form>
						</div>
					</>
				);
			}}
		</Form.Context>
	);
};
